<template>
  <div class="assets" style="padding-bottom: 60px;">
    <div class="header">
      <van-nav-bar :title="$t('assets.title')"> </van-nav-bar>
    </div>
    <div class="titleTv1">{{ $t("fund.usable") }}</div>
    <div class="titleTv2">
      {{ balance.total }}
      <!-- {{ parseFloat(balance.total / 99.99).toFixed(2) }}(USDT) -->
      <!-- {{ balance.total }}
      <span v-if="lang === 'en'">
        (INR) ≈ {{ parseFloat(balance.total / 99.99).toFixed(2) }}(USDT)
      </span>
      <span v-if="lang === 'ru'">
        (RUB) ≈ {{ parseFloat(balance.total / 80.65).toFixed(2) }}(USDT)
      </span>
      <span v-if="lang === 'zh'">
        (KBW) ≈ {{ parseFloat(balance.total / 1305.3).toFixed(2) }}(USDT)
      </span>
      <span v-if="lang === 'fr'">
        (JPY) ≈ {{ parseFloat(balance.total / 138.9).toFixed(2) }}(USDT)
      </span>
      <span v-if="lang === 'it'">
        (BRL) ≈ {{ parseFloat(balance.total / 5.0).toFixed(2) }}(USDT)
      </span>
      <span v-if="lang === 'es'">
        (MXN) ≈ {{ parseFloat(balance.total / 17.36).toFixed(2) }}(USDT)
      </span> -->
    </div>
    <van-row class="centRow">
      <van-col span="12">
        <div class="centV1">
          <span class="titleTv3">{{ $t("assets.tprofit") }}</span>
          <span class="titleTv4">
            {{ tongji["profit"] }}
            <!-- <div v-if="lang === 'en'">(INR)</div>
            <div v-if="lang === 'ru'">(RUB)</div>
            <div v-if="lang === 'zh'">(KBW)</div>
            <div v-if="lang === 'fr'">(JPY)</div>
            <div v-if="lang === 'it'">(BRL)</div>
            <div v-if="lang === 'es'">(MXN)</div> -->
          </span>
        </div>
      </van-col>
      <van-col span="12">
        <div class="centV2">
          <span class="titleTv3">{{ $t("assets.tamount") }}</span>
          <span class="titleTv4">{{ tongji["price"] }}</span>
        </div>
      </van-col>
    </van-row>
    <van-row class="centRow2">
      <van-col span="12">
        <div class="centV1">
          <span class="titleTv3">{{ $t("assets.torders") }}</span>
          <span class="titleTv4">{{ tongji["count"] }}</span>
        </div>
      </van-col>
      <van-col span="12"> </van-col>
    </van-row>
    <van-row class="buttonV">
      <van-col span="8" @click="recharge">
        <span class="buttonTv1">{{ $t("assets.charge") }}</span>
      </van-col>
      <van-col span="8" @click="withdraw">
        <span class="buttonTv1">{{ $t("assets.withdraw") }}</span>
      </van-col>

      <van-col span="8" @click="$router.push({ path: '/trade/shopping/trading/' + 1 })">
        <span class="buttonTv1">{{ $t("trade.deal") }}</span>
      </van-col>
    </van-row>

    <div class="tabV">
      <div @click="onConstructionClick(item)" class="tabCentV" v-for="(item, index) in tabList" :key="index">
        <img class="tabCentIv" :src="item.img" />
        <span class="tabCentTv">{{ item.name }}</span>
      </div>
    </div>

    <img class="bottomIv" src="../../assets/img/banner.png" />

    <van-dialog v-model:show="payPup" :title="$t('NoPaymentPassword')" :cancel-button-text="$t('common.cancel')"
      :confirm-button-text="$t('common.confirm')" @confirm="onConfirmClick()" show-cancel-button>
    </van-dialog>

    <van-dialog v-model:show="mC2CDepositPup" :showConfirmButton="false">
      <div class="pupV">
        <span>{{ $t("fbuy.tips") }}</span>
        <div class="nationV" @click="onCityClick">
          <span v-if="mNation" class="nationTv2">
            <img class="nationIV" :src="mNation.icon" />

            <span v-if="lang === 'en'" class="nationTv3">
              {{ mNation.enname }}
            </span>
            <span v-if="lang === 'ru'" class="nationTv3">
              {{ mNation.runame }}
            </span>
            <span v-if="lang === 'zh'" class="nationTv3">
              {{ mNation.cnname }}
            </span>
            <span v-if="lang === 'fr'" class="nationTv3">
              {{ mNation.frname }}
            </span>
            <span v-if="lang === 'it'" class="nationTv3">
              {{ mNation.itname }}
            </span>
            <span v-if="lang === 'es'" class="nationTv3">
              {{ mNation.esname }}
            </span>
          </span>
          <span v-else class="nationTv1">{{ $t("PleaseSelectACountry") }}</span>
          <van-icon name="arrow" color="#dcdcdc" />
        </div>
        <div class="inputV">
          <van-field v-model="mPrice" type="number" :placeholder="$t('PleaseAmount')" />
        </div>
        <div class="centTv1">{{ $t("c2cti") }}</div>
        <div class="centTv2">{{ $t("warn") }}</div>
        <div class="pupBottom">
          <span class="pupCancel" @click="mC2CDepositPup = false">{{ $t('common.cancel') }}</span>
          <span class="pupSub" @click="onSubClick">{{ $t('common.confirm') }}</span>
        </div>
      </div>
    </van-dialog>

    <!-- 提现弹窗 -->
    <van-dialog v-model:show="mC2CWithdrawalPup" :showConfirmButton="false">
      <div class="pupV">
        <span>{{ $t("fbuy.tips") }}</span>
        <div class="nationV" @click="onCityClick">
          <span v-if="mNation" class="nationTv2">
            <img class="nationIV" :src="mNation.icon" />

            <span v-if="lang === 'en'" class="nationTv3">
              {{ mNation.enname }}
            </span>
            <span v-if="lang === 'ru'" class="nationTv3">
              {{ mNation.runame }}
            </span>
            <span v-if="lang === 'zh'" class="nationTv3">
              {{ mNation.cnname }}
            </span>
            <span v-if="lang === 'fr'" class="nationTv3">
              {{ mNation.frname }}
            </span>
            <span v-if="lang === 'it'" class="nationTv3">
              {{ mNation.itname }}
            </span>
            <span v-if="lang === 'es'" class="nationTv3">
              {{ mNation.esname }}
            </span>
          </span>
          <span v-else class="nationTv1">{{ $t("PleaseSelectACountry") }}</span>
          <van-icon name="arrow" color="#dcdcdc" />
        </div>

        <div class="centTv1">{{ $t("c2cTisp") }}</div>
        <div class="centTv2">{{ $t("warn") }}</div>
        <div class="pupBottom">
          <span class="pupCancel" @click="mC2CWithdrawalPup = false">{{ $t('common.cancel') }}</span>
          <span class="pupSub" @click="onWithdrawal">{{ $t('common.confirm') }}</span>
        </div>
      </div>
    </van-dialog>

    <!-- 30秒等待弹窗 -->
    <van-dialog v-model:show="isWaitSecond" :showConfirmButton="false" closeOnClickOverlay>

      <div class="wait">
        <div class="animation-box">
          <img src="../../assets/img/2.gif" alt="" style="width: 80px;height: 80px;">
        </div>
        <span style="margin:0px 20px 20px 20px; font-size: 12px;">{{ $t('WaitSecond') }}</span>
      </div>

    </van-dialog>

    <van-popup v-model="cityPop" position="bottom">
      <div class="cityPopV">
        <span class="cityPopTitle">{{ $t("PleaseSelectACountry") }}</span>
        <div class="itemV" v-for="(item, index) in mNationList" @click="onPopCilck(item)">
          <img class="itemTv1" :src="item.icon" />
          <span v-if="lang === 'en'" class="itemTv2">
            {{ item.enname }}
          </span>
          <span v-if="lang === 'ru'" class="itemTv2">
            {{ item.runame }}
          </span>
          <span v-if="lang === 'zh'" class="itemTv2">
            {{ item.cnname }}
          </span>
          <span v-if="lang === 'fr'" class="itemTv2">
            {{ item.frname }}
          </span>
          <span v-if="lang === 'it'" class="itemTv2">
            {{ item.itname }}
          </span>
          <span v-if="lang === 'es'" class="itemTv2">
            {{ item.esname }}
          </span>
          <span>+{{ item.code }}</span>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { Dialog } from "vant";
export default {
  data() {
    return {
      lang: localStorage.getItem("lang"),
      eye: true,
      // canvas
      context: {},
      score: 0,
      userinfo: {},
      balance: {},
      tongji: [],
      payPup: false,
      tabList: [
        {
          id: 0,
          name: this.$t("DepositHistory"),
          img: require("../../assets/img/tab1.svg"),
        },
        {
          id: 1,
          name: this.$t("WithdrawHistory"),
          img: require("../../assets/img/tab2.svg"),
        },
        {
          id: 2,
          name: this.$t("TransactionHistory"),
          img: require("../../assets/img/tab3.svg"),
        },
        {
          id: 3,
          name: this.$t("WalletAddress"),
          img: require("../../assets/img/tab4.svg"),
        }
        // {
        //   id: 4,
        //   name: this.$t("C2CDeposit"),
        //   img: require("../../assets/img/tab5.svg"),
        // },
        // {
        //   id: 5,
        //   name: this.$t("C2CDepositHistory"),
        //   img: require("../../assets/img/tab6.svg"),
        // },
        // {
        //   id: 6,
        //   name: this.$t("C2CWithdrawal"),
        //   img: require("../../assets/img/tab7.svg"),
        // },
        // {
        //   id: 7,
        //   name: this.$t("C2CWithdrawalHistory"),
        //   img: require("../../assets/img/tab8.svg"),
        // },
      ],
      mC2CDepositPup: false,
      mC2CWithdrawalPup: false,
      mNationList: [],
      mNation: "",
      mPrice: "",
      cityPop: false,
      isWaitSecond: false,
      serverurl:"",
    };
  },
  created() {
    this.getinfo();
    this.getcodelist();
    this.getdata();
  },
  mounted() { },
  methods: {
    // 获取客服
    async getdata() {
      const { data } = await this.$http.get("/home/home/index");
      if (data) {
        if (data.code === 200) {
          this.serverurl = data.data.serverurl;
        }
      }
    },
 
    // 获取区号
    async getcodelist() {
      const { data } = await this.$http.get("/home/home/getcodelist");
      if (data) {
        if (data.code === 200) {
          this.mNationList = data.data;
          // data.data.forEach((item) => {
          //   this.option1.push({ text: item.enname, value: item.id });
          // });
        }
      }
    },
    async getinfo() {
      const { data } = await this.$http.get("/home/user/index");
      if (data) {
        if (data.code === 200) {
          this.score = +data.data.userinfo.credit_score;
          this.userinfo = data.data.userinfo;
          this.balance = data.data.balance;
          this.tongji = data.data.tongji;
        }
      }
    },
    eyeevent() {
      this.eye = !this.eye;
    },
    // 充值
    recharge() {
      this.$router.push("/assets/recharge");
    },
    // 提币
    withdraw() {
      if (this.userinfo.pay_pwd == null) {
        this.payPup = true;
      } else {
        this.$router.push("/assets/withdraw");
      }
    },
    onConfirmClick() {
      this.$router.push("/person/setting/paypassword");
    },
    // 划转
    transfer() {
      this.$router.push("/assets/transfer");
    },
    // 兑换
    conversion() {
      this.$router.push("/assets/conversion");
    },
    //
    async onConstructionClick(item) {
      if (item.id == 0) {
        this.$router.push({ path: "/trade/shopping/trading/" + 1 });
      } else if (item.id == 1) {
        this.$router.push({ path: "/trade/shopping/trading/" + 2 });
      } else if (item.id == 2) {
        this.$router.push({ path: "/trade/shopping/trading/" + 3 });
      } else if (item.id == 3) {
        this.$router.push({ path: "/assets/WalletAddress/" + 1 });
      } else if (item.id == 4) {
        const { data } = await this.$http.get("/home/user/checkRecharge");
        if (data) {
          if (data.code === 200) {
            this.mNation = "";
            this.mC2CDepositPup = true;
          } else if (data.code === 40001) {
            Dialog.alert({
              title: this.$t("sell.tips"),
              message: this.$t("PaymentOrderNotCompleted"),
              cancelButtonText: this.$t("common.cancel"),
              confirmButtonText: this.$t("common.confirm"),
              showCancelButton: true,
            })
              .then(() => {
                this.$router.push({ path: "/assets/WithdrawList" });
              })
              .catch(() => {
                // on cancel
              });
          }
        }
      } else if (item.id == 5) {
        this.$router.push({ path: "/assets/WithdrawList" });
      } else if (item.id == 6) {
        this.mNation = "";
        this.mC2CWithdrawalPup = true;
      } else if (item.id == 7) {
        this.$router.push({ path: "/assets/C2CWithdrawalList" });
      }
    },
    onCityClick() {
      this.cityPop = true;
    },
    //提交充值
    async onSubClick() {
      if (!this.mPrice) {
        this.$toast("请输入充值金额");
        return;
      }
      const postform = {
        c_id: this.mNation.id,
        price: this.mPrice,
      };
      const { data } = await this.$http.post("/home/user/rechargeC", postform);
      if (data) {
        if (data.code === 200) {
          this.mC2CDepositPup = false;
          this.isWaitSecond = true;
          setTimeout(() => {
            this.isWaitSecond = false;
          }, 30000)

        } else {
        }
      }
    },
    // 提交提现
    onWithdrawal() {

      if (this.mNation.id) {
        
        return this.$router.push({ path: "/assets/C2CWithdrawal/" + this.mNation.id });
      }
      this.$toast(this.$t("PleaseSelectACountry"));

    },
    //选择国家
    onPopCilck(item) {
      this.mNation = item;
      this.cityPop = false;
    }
  },
};
</script>
<style lang="less" scoped>
.assets {
  background-color: #fff;
}

.titleTv1 {
  color: #828282;
  font-size: 1rem;
  padding-top: 65px;
  display: flex;
  padding-left: 3%;
  font-weight: 500;
}

.titleTv2 {
  color: #222732;
  font-size: 1.3rem;
  font-weight: 500;
  display: flex;
  padding-left: 3%;
  margin-top: 8px;
}

.centRow {
  border-top: 1px solid #f5f5f5;
  border-bottom: 1px solid #f5f5f5;
  margin-top: 20px;
}

.centRow2 {
  border-bottom: 1px solid #f5f5f5;
}

.centV1 {
  padding-top: 15px;
  padding-bottom: 15px;
  box-sizing: border-box;
  border-right: 1px solid #f5f5f5;
  display: flex;
  flex-direction: column;
}

.centV2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.titleTv3 {
  display: flex;
  align-items: center;
  color: #828282;
  font-size: 0.9rem;
  font-weight: 500;
  margin-left: 1rem;
  text-align: left;
  word-wrap: break-word;
}

.titleTv4 {
  display: flex;
  align-items: center;
  color: #222732;
  font-weight: 500;
  font-size: 1rem;
  margin-top: 10px;
  margin-left: 1rem;
}

.buttonV {
  margin-top: 20px;

  .van-col {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .buttonTv1 {
    background-color: #e3e6ea;
    border-radius: 5px;
    font-size: 1rem;
    color: #222732;
    padding-top: 7px;
    padding-bottom: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
  }
}

.wait {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.animation-box {
  display: flex;
  height: 80px;
  width: 80px;
  justify-content: space-between;

}

.tabV {
  border-top: 5px solid #f5f5f5;
  border-bottom: 5px solid #f5f5f5;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.tabCentV {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;

  .tabCentIv {
    width: 28px;
  }

  .tabCentTv {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: #828282;
    margin-top: 5px;
  }
}

.bottomIv {
  margin-top: 20px;
  width: 92%;
}

.diaV {
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .diaTv1 {
    margin-top: 20px;
    color: #333;
    font-size: 1rem;
  }

  .diaTv2 {
    margin-top: 34px;
    background: #000000;
    border-radius: 20px;
    font-weight: 500;
    font-size: 13px;
    color: #fff;
    height: 40px;
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.pupV {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.van-dropdown-menu {
  width: 90%;
  border: 0.5px solid #e7f2fe;
  margin-top: 20px;
}

.van-dropdown-menu__title {
  width: 90%;
  padding: 0;
}

.van-dropdown-item__option--active .van-dropdown-item__icon {
  color: #004ea3 !important;
}

.inputV {
  width: 90%;
  border: 0.1px solid #dcdcdc;
  margin-top: 10px;
}

.van-cell {
  background-color: #00000000;
}

.centTv1 {
  margin-top: 10px;
  background-color: #f6f6f6;
  padding: 5px;
  display: flex;
  width: 90%;
  font-size: 13px;
  color: #333;
}

.centTv2 {
  margin-top: 10px;
  width: 90%;
  font-size: 13px;
  color: #e74b71;
  display: flex;
  font-weight: 500;
  text-align: left;
}

.group-shake {
  animation: shake 0.5s;
  animation-iteration-count: infinite;
}

.shake {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: red;
}

.pupBottom {
  width: 100%;
  display: flex;
  align-items: center;
  border-top: 0.1px solid #eee;
  margin-top: 10px;

  .pupCancel {
    height: 3rem;
    flex: 1;
    border-right: 0.1px solid #eee;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pupSub {
    flex: 1;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f0b82d;
  }
}

.cityPopV {
  height: 50vh;
  padding-top: 20px;
}

.nationV {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.1px solid #dcdcdc;
  margin-top: 10px;
  padding: 10px 16px;
}

.nationTv1 {
  flex: 1;
  color: #969799;
  font-size: 15px;
  text-align: left;
}

.nationTv2 {
  flex: 1;
  color: #323232;
  font-size: 15px;
  text-align: left;
  display: flex;
  align-items: center;
}

.nationIV {
  width: 40px;
  height: 25px;
}

.nationTv3 {
  color: #323232;
  font-size: 15px;
  margin-left: 5px;
}

.cityPopTitle {
  font-size: 1rem;
  font-weight: 500;
}

.itemV {
  display: flex;
  align-items: center;
  height: 45px;
  padding-left: 1rem;
  padding-right: 1rem;

}

.itemTv1 {
  width: 40px;
  height: 25px;
}

.itemTv2 {
  color: #4d5260;
  font-size: 15px;
  flex: 1;
  text-align: left;
  margin-left: 10px;
}
</style>
